<template>
  <el-select
    v-model="model"
    :placeholder="`${$t('IMPORTS.CHOOSE_MODEL')}`"
    @change="modelChanged"
  >
    <el-option
      v-for="(model, index) in models"
      :key="index"
      :value="model.key"
      :label="model.model"
    />
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";
import importModels from "@/views/Pages/AdminModule/ImportModule/importModels";

export default {
  name: "import-model-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: ["models", "modelDefaultValue"],

  data() {
    return {
      model: this.modelDefaultValue ?? null,
    };
  },

  methods: {
    modelChanged(key) {
      const model = this.models.find((item) => item.key === key);
      this.$emit("importModelChanged", model);
    },
  },
};
</script>

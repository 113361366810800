<template>
  <div class="csv-uploader-part-two">
    <div class="table-responsive">
      <form ref="fieldMappingFormRef" @submit.prevent="onSubmitForm">
        <table class="table">
          <thead class="has-gutter">
            <tr class="thead-light align-center text-center">
              <th>Import Model Fields</th>
              <th>Columns in your file</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(template, index) in modelHeaders"
              :key="index"
              class="align-center text-center"
            >
              <td>
                {{ template.name }}
                <span v-if="template.required" class="text-danger"> (*) </span>
              </td>

              <td>
                <select
                  :required="template.required"
                  v-model="selectedFields[index]"
                  @change.prevent="mapFields"
                >
                  <option value="" selected>Select a column</option>
                  <option
                    v-for="(fileColumn, _index) in csvHeaders"
                    :key="_index"
                    :value="fileColumn"
                    :disabled="fieldSelected(fileColumn, index)"
                  >
                    {{ fileColumn }}
                  </option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <button type="submit" style="display: none" ref="stepTwoSubmitButton" />
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    csvHeaders: {
      type: Array,
      required: true,
    },
    defaultSelectedFields: {
      required: false,
    },
    submitSecondStepForm: {
      type: Function,
      required: true,
    },
    goToThirdStep: {
      type: Function,
      required: true,
    },
    modelHeaders: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedFields: [],
      result: {},
    };
  },

  watch: {},

  created() {
    let selectedFields = [];
    this.modelHeaders.map((item) => {
      if (this.defaultSelectedFields.hasOwnProperty(item.code)) {
        selectedFields.push(this.defaultSelectedFields[item.code]);
      } else {
        selectedFields.push(null);
      }
    });
    this.selectedFields = selectedFields;
  },

  methods: {
    fieldSelected(field, currentIndex) {
      return this.selectedFields.some(
        (selected, index) => selected === field && index !== currentIndex
      );
    },

    onSubmitForm() {
      this.goToThirdStep();
    },

    mapFields() {
      for (const [index, object] of Object.entries(this.modelHeaders)) {
        const templateColumn = object.code;
        const selectedField = this.selectedFields[Number(index)];

        if (selectedField) this.result[templateColumn] = selectedField;
      }
      const data = { ...this.result };
      this.$emit("mapping-result", data);
    },
  },
};
</script>

export default {
  type: "imports",
  name: null,
  model: null,
  excerpt: "",
  results: {},
  mapping: {},
  identifier: {
    model_field: "",
    csv_field: "",
  },
  relationshipNames: ["organization", "linkedObject"],
  organization: {
    type: "organizations",
    id: null,
  },
  linkedObject: {
    type: "suppliers",
    id: null,
  },
};

<template>
  <div class="csv-uploader">
    <div class="form">
      <div class="csv-uploader-part-one">
        <div class="form-group csv-uploader-file">
          <input
            type="file"
            @change.prevent="handleFile"
            class="form-control-file"
          />
          <slot name="error" v-if="isValidFileMimeType === false">
            <div class="invalid-feedback d-block">File type is invalid</div>
          </slot>
        </div>
      </div>
    </div>

    <div class="mt-3">{{ $t("IMPORTS.COUNT_CSV_ROWS") }} : {{ fileRows }}</div>
  </div>
</template>

<script>
import Papa from "papaparse";

export default {
  props: {
    fileMimeTypes: {
      type: Array,
      default: () => [
        "text/csv",
        "text/x-csv",
        "application/vnd.ms-excel",
        "text/plain",
      ],
    },
  },

  data() {
    return {
      csvData: null,
      isValidFileMimeType: null,
      fileRows: 0,
    };
  },

  methods: {
    async handleFile(event) {
      const file = event.target?.files?.[0];

      if (file) {
        this.isValidFileMimeType = this.fileMimeTypes.includes(file.type);

        if (!this.isValidFileMimeType) {
          return;
        }

        this.$emit("get-csv-file", file);
        this.createCsvFile(file);
      }
    },

    createCsvFile(file) {
      const reader = new FileReader();
      reader.onload = () => {
        const contents = reader.result;
        this.parseCsv(contents);
      };
      reader.readAsText(file, "UTF-8");
    },

    parseCsv(contents) {
      Papa.parse(contents, {
        skipEmptyLines: true,
        header: true,
        complete: (results) => {
          const data = {
            headers: Object.keys(results.data[0]),
            rows: results.data.map((row) => Object.values(row)),
          };

          this.fileRows = data.rows.length;

          this.$emit("csv-uploaded", data);
        },
      });
    },
  },
};
</script>

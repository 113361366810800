<template>
  <ImportWizardTable :content-headers="headers" :content-rows="rows" />
</template>

<script>
import ImportWizardTable from "@/views/Pages/AdminModule/ImportModule/Partials/Wizard/ImportWizardTable.vue";

export default {
  components: { ImportWizardTable },

  props: ["headers", "rows"],
};
</script>
